import React from 'react';
import styled from 'styled-components';

const Link = ({ newTab, children, href, isUnderlined }) => (
    <A
        href={href}
        isUnderlined={isUnderlined}
        rel={newTab ? 'noopener noreferrer' : undefined}
        target={newTab ? '_blank' : undefined}
    >
        {children}
    </A>
);

const A = styled.a`
    ${props => props.isUnderlined ? `text-decoration: underline;` : null}

    &:hover {
        text-decoration: underline;
    }
`;
export default Link;