import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import ThingRotator from './ThingRotator';

const Footer = () => (
    <Container>
        <Group>
            <li>Austen Payan</li>
            <li>austenpayan@gmail.com</li>
            <li><ThingRotator/></li>
            <li>New York, New York</li>
        </Group>
        <Group>
            <li><Link href="https://github.com/austenpayan" newTab={true}>Github</Link></li>
            <li><Link href="https://www.linkedin.com/in/austen-payan-85baa764" newTab={true}>LinkedIn</Link></li>
            <li><Link href="https://twitter.com/a__pay" newTab={true}>Twitter</Link></li>
            <li><Link href="https://www.instagram.com/a__pay" newTab={true}>Instagram</Link></li>
        </Group>
    </Container>
);

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 170px 0px 50px 0px;

    @media(max-width: 600px) {
        display: block;
    }
`;

const Group = styled.ul`
    line-height: 1.7;

    &:last-child {
        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }
`;

export default Footer;
