import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import styles from '@styles/nav.module.scss';

const Header = () => (
    <Base>
        <Container>
            <Link
                to="/"
            >
                austen payan
            </Link>
            <nav>
                <ul>
                    <NavItem>
                        <Link
                            activeClassName={styles.active}
                            className={styles.navlink}
                            to="/work/"
                        >
                            index
                    </Link>
                    </NavItem>
                    <NavItem>
                        <a href="mailto:austenpayan@gmail.com">
                            contact
                        </a>
                    </NavItem>
                </ul>
            </nav>
        </Container>
    </Base>
);

const Container = styled.div`
    width: 77%;
    max-width: 1152px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 12px;

    @media (max-width: 500px) {
        width: 85%;
    }
`;

const Base = styled.div`
    width: 100%;
    overflow: auto;
    font-size: 1.05rem;
    position: fixed;
    z-index: 9;
    overflow: auto;
    background-color: black;
`;

const NavItem = styled.li`
    display: inline-block;

    &:not(:last-child) {
        margin-right: 30px;
    }
`;

export default Header;