// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-city-defense-js": () => import("./../../../src/pages/work/city-defense.js" /* webpackChunkName: "component---src-pages-work-city-defense-js" */),
  "component---src-pages-work-gatku-js": () => import("./../../../src/pages/work/gatku.js" /* webpackChunkName: "component---src-pages-work-gatku-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-rollerblade-js": () => import("./../../../src/pages/work/rollerblade.js" /* webpackChunkName: "component---src-pages-work-rollerblade-js" */),
  "component---src-pages-work-slack-js": () => import("./../../../src/pages/work/slack.js" /* webpackChunkName: "component---src-pages-work-slack-js" */),
  "component---src-pages-work-visit-js": () => import("./../../../src/pages/work/visit.js" /* webpackChunkName: "component---src-pages-work-visit-js" */),
  "component---src-pages-work-zingle-js": () => import("./../../../src/pages/work/zingle.js" /* webpackChunkName: "component---src-pages-work-zingle-js" */)
}

