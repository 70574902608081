import React from 'react';
import Header from '../components/Header.js';
import styled, { createGlobalStyle } from "styled-components"
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"

const GlobalStyle = createGlobalStyle`
    body {
        background-color: black;
        font-size: 15px;
        min-height: 100vh;
        width: 100vw;
        color: white !important;
        display: block;
        position: relative;
        letter-spacing: 0.04rem;
        font-family: aktiv-grotesk-std, sans-serif !important;
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        overflow-x: hidden;
        overflow-y: auto;
    }
`;

const Page = ({ children }) => (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Frontend Engineer @ Slack, building web applications that are fast, beautiful, and organized."></meta>
        </Helmet>
        <GlobalStyle />
        <Header />
        <Body>
            <Container>
                <Content>
                    {children}
                    <Footer />
                </Content>
            </Container>
        </Body>
    </>
);

const Body = styled.div`
    width: 100%;
`;
// Any updates here need to be made to Container
// in Header.js
export const Container = styled.div`
    margin: auto;
    width: 77%;
    max-width: 1152px;

    @media (max-width: 500px) {
        width: 85%;
    }
`;

const Content = styled.div`
    padding-top: 150px;
`;

export default Page;