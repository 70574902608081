import { useState, useEffect } from 'react';

const items = [
    'React',
    'Gatsby',
    'Styled Components',
    'love',
    'sunshine',
    'Chrome',
    'Typekit',
    'VSCode',
    'Github',
    'feedback',
    'Soylent',
    'a walk outside',
    'sleep',
    'Stack Overflow',
    'Aktiv Grotesk',
    'inspiration',
    'purpose',
    'water',
    'friends',
    'colleagues',
    'La Croix',
    'bike rides',
];

const Rotator = () => {
    const [idx, setIdx] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIdx((idx + 1) % items.length);
        }, 500);

        return function cleanup() {
            clearTimeout(timeout);
        };
    });

    return `Made with ${items[idx]}`;
};

export default Rotator;